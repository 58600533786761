<template>
  <div class='addunit'>
    <el-button size="small" @click="$router.go(-1)">返回</el-button>
    <el-row :gutter="20" class="unitrow">
      <el-col :span="10">
        <div id="unitmap"></div>
      </el-col>
      <el-col :span="12">
        <el-form :model="unitForm" :rules="rules" label-width="120px" ref="unitForm" class="unitForm" :hide-required-asterisk="true">
          <el-form-item prop="school" label="单位名称： ">
            <el-input v-model="unitForm.school" placeholder="请输入单位名称" id="facilityLocation">
            </el-input>
          </el-form-item>
          <el-form-item prop="address" label="详细地址： ">
            <el-input v-model="unitForm.address" placeholder="请输入详细地址">
            </el-input>
          </el-form-item>
          <el-form-item prop="contactPerson" label="负责人： ">
            <el-input v-model="unitForm.contactPerson" placeholder="请输入负责人姓名">
            </el-input>
          </el-form-item>
          <el-form-item prop="contactPhone" label="联系电话： ">
            <el-input v-model="unitForm.contactPhone" placeholder="请输入联系电话">
            </el-input>
          </el-form-item>
          <el-form-item label="经度： ">
            <el-input v-model="unitForm.longitude" :disabled="true">
            </el-input>
          </el-form-item>
          <el-form-item label="纬度： ">
            <el-input v-model="unitForm.latitude" :disabled="true">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" :loading="addLoading" @click="submitForm('unitForm')">{{this.$route.query.id?'编辑':'添加'}}</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AMap from 'AMap'
import { addUnit, getSchoolList, editUnit } from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      unitForm: {
        school: '',
        address: '',
        contactPerson: '',
        contactPhone: '',
        longitude: '',
        latitude: ''
      },
      rules: {
        school: [
          { required: true, message: '请输入单位名称', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入单位详细地址', trigger: 'blur' }
        ],
        contactPerson: [
          { required: true, validator: this.$validator.username, trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, validator: this.$validator.contactPhone, trigger: 'blur' }
        ]
      },
      map: null,
      addLoading: false
    };
  },
  created () {

  },
  mounted () {
    this.initMap()
  },
  computed: {
    ...mapGetters(['realname']),
  },
  methods: {
    initMap () {
      // let arr = this.state.maplist
      var that = this
      this.map = new AMap.Map("unitmap", {
        // mapStyle: 'amap://styles/bf1ea8bba3f1078dc72353a105738e82',
        center: [120.17, 30.25],//地图中心点
        zoom: 14,//地图显示的缩放级别
        // viewMode: '3D',
        pitch: 30,
        viewMode: '3D',
        //设置地图背景图
        // mapStyle: 'amap://styles/40035571fa9fdd05a26fe1b05f48fdc9'

      });
      new AMap.DistrictSearch({
        extensions: 'all',
        subdistrict: 0
      }).search('上城区', function (status, result) {
        // 外多边形坐标数组和内多边形坐标数组
        var outer = [
          new AMap.LngLat(-360, 90, true),
          new AMap.LngLat(-360, -90, true),
          new AMap.LngLat(360, -90, true),
          new AMap.LngLat(360, 90, true),
        ];
        var holes = result.districtList[0].boundaries

        var pathArray = [
          outer
        ];
        pathArray.push.apply(pathArray, holes)
        var polygon = new AMap.Polygon({
          pathL: pathArray,
          strokeColor: 'red',//城市边界颜色
          strokeWeight: 1,
          fillColor: '#220986', // 遮罩背景色黑色
          fillOpacity: 0.1
        });
        polygon.setPath(pathArray);
        that.map.add(polygon);
      })
      // 地图点击获取位置
      this.map.on('click', function (ev) {
        const lnglat = [ev.lnglat.lng, ev.lnglat.lat]
        AMap.plugin('AMap.Geocoder', function () {
          let geocoder = new AMap.Geocoder({
            // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
            city: '浙江省'
          })

          geocoder.getAddress(lnglat, function (status, result) {
            // console.log(result)
            if (status === 'complete' && result.info === 'OK') {
              const { addressComponent } = result.regeocode
              // console.log('addressComponent :>> ', result.regeocode.formattedAddress.split("街道"));
              that.unitForm.school = result.regeocode.formattedAddress.split("街道")[1]
              that.unitForm.address = result.regeocode.formattedAddress
              that.unitForm.longitude = lnglat[0]
              that.unitForm.latitude = lnglat[1]
              that.unitForm.regionCode = addressComponent.adcode
              that.unitForm.province = addressComponent.province
              that.unitForm.city = addressComponent.city
              that.unitForm.area = addressComponent.district
            }
          })
        })
      });
      // 输入单位名称搜索获取位置
      AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Geocoder'], function () {
        var autoOptions = {
          city: "浙江省", //城市，默认全国
          citylimit: true,
          input: "facilityLocation",//使用联想输入的input的id
        };
        var autocomplete = new AMap.Autocomplete(autoOptions);
        let geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: '浙江省'
        })
        var placeSearch = new AMap.PlaceSearch({
          map: that.map
        })
        // 单位名称搜索
        AMap.event.addListener(autocomplete, "select",
          function (e) {
            //TODO 针对选中的poi实现自己的功能
            placeSearch.setCity(e.poi.adcode);
            placeSearch.search(e.poi.name);
            that.unitForm.address = e.poi.district + e.poi.address
            that.unitForm.longitude = e.poi.location.lng
            that.unitForm.latitude = e.poi.location.lat
            that.unitForm.school = e.poi.name
            geocoder.getAddress([e.poi.location.lng, e.poi.location.lat], function (status, result) {
              if (status === 'complete' && result.info === 'OK') {
                // result中对应详细地理坐标信息
                const { addressComponent } = result.regeocode
                that.unitForm.regionCode = addressComponent.adcode
                that.unitForm.province = addressComponent.province
                that.unitForm.city = addressComponent.city
                that.unitForm.area = addressComponent.district
              }
            })
          },
        );
      });
      // 编辑
      if (this.$route.query.id) {
        getSchoolList(
          {
            provinceId: 31,
            cityId: 383,
            districtId: 3230,
            schoolId: this.$route.query.id
          }
        ).then(res => {
          this.unitForm = res[0]
          this.map.add(new AMap.Marker({
            position: new AMap.LngLat(that.unitForm.longitude, that.unitForm.latitude),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: that.unitForm.school
          }))
        })
      }
    },
    submitForm () {
      this.$refs.unitForm.validate((valid) => {
        if (valid) {
          const { school, address, latitude, longitude, contactPerson, contactPhone } = this.unitForm
          const params = {
            provinceId: 31,
            cityId: 383,
            districtId: 3230,
            school,
            address,
            longitude,
            latitude,
            contactPerson,
            contactPhone
          }
          if (this.$route.query.id) {
            params.id = this.$route.query.id
            editUnit(params).then(() => {
              this.$message.success('单位修改成功！')
              this.$add(
                {
                  userName: this.realname,
                  module: '单位管理',
                  content: `${this.realname}修改了${school}信息`,
                  type: 5,
                  platform: 8,
                  operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
                }
              )
              this.$store.dispatch('permission/actionUnit')
              this.$router.push('/unit')
            })
          } else {
            addUnit(params).then(() => {
              this.$message.success('单位添加成功！')
              this.$add(
                {
                  userName: this.realname,
                  module: '单位管理',
                  content: `${this.realname}添加了${school}`,
                  type: 3,
                  platform: 8,
                  operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
                }
              )
              this.$store.dispatch('permission/actionUnit')
              this.$router.push('/unit')
            })
          }
          // this.addLoading = false

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
.addunit {
  position: relative;
  min-height: 70vh;
  > .el-button {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10000;
  }
  .location {
    span {
      font-size: 18px;
      margin-right: 10px;
    }
    .el-select {
      width: 300px;
    }
  }
  .unitrow {
    margin-top: 30px;
    #unitmap {
      margin-left: 18px;
      width: 100%;
      height: 60vh;
    }
    ::v-deep .unitForm {
      margin-top: 20px;
      .el-form-item__label {
        // color: #30c6fe;
        font-size: 16px;
      }
      .el-form-item__content {
        text-align: right;
        .el-input {
          .el-input__inner {
            background: none;
            // border: 1px solid #30c6fe;
            // color: #fff;
          }
        }
        .is-disabled {
          .el-input__inner {
            border: none;
          }
        }
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>
